import { isDefined } from "@clipboard-health/util-ts";
import { useParams } from "react-router-dom";

import { PROFESSIONAL_REFERENCE_URL_APP_V1 } from "../constants";
import { ACCOUNTS_BASE_PATH, ACCOUNTS_DOCUMENT_DETAILS_PATH } from "../paths";
import { type DocumentRequirementType } from "../types";

interface UseGetDocumentDetailsRouteOptions {
  shouldRouteToProfessionalReference: boolean;
  requirementId: string;
  documentRequirementType: DocumentRequirementType;
  qualification?: string;
}

export function useGetDocumentDetailsRoute(options: UseGetDocumentDetailsRouteOptions): string {
  const params = useParams<{ hcfId?: string; hcfName?: string }>();

  const {
    shouldRouteToProfessionalReference,
    requirementId,
    documentRequirementType,
    qualification,
  } = options;

  if (shouldRouteToProfessionalReference) {
    return PROFESSIONAL_REFERENCE_URL_APP_V1;
  }

  const qualificationQueryParameter = isDefined(qualification)
    ? `&qualification=${qualification}`
    : "";

  const shouldFetchFacilitySpecificDocumentDetails =
    isDefined(params?.hcfId) && isDefined(params?.hcfName);

  const defaultDocumentDetailsRoute = `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENT_DETAILS_PATH}?requirement=${requirementId}&requirementStatus=${documentRequirementType}${qualificationQueryParameter}`;

  return shouldFetchFacilitySpecificDocumentDetails
    ? `${defaultDocumentDetailsRoute}&hcfId=${params?.hcfId ?? ""}${qualificationQueryParameter}`
    : defaultDocumentDetailsRoute;
}
