import { isDefined } from "@clipboard-health/util-ts";
import {
  defaultHcpRequirementStatusApiResponse,
  deprecatedGetRequirementStatusDoNotUseGraphQlCH23034,
  GET_REQUIREMENTS_STATUS_PATH,
} from "@src/appV2/Accounts/Documents/api/deprecatedDoNotUseGraphQlCH23034";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  type QueryClient,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";

import {
  type HcpRequirement,
  type HcpRequirementStatusApiResponse,
  type HcpRequirementStatusResponse,
  MappingLevel,
} from "../types";

export interface UseGetMissingRequirements {
  pendingDocuments: Set<HcpRequirement>;
  missingDocuments: Set<HcpRequirement>;
}

interface UseGetMissingRequirementsByWorkplaces {
  results: Map<string, UseGetMissingRequirements>;
}

export interface WorkplaceDataForGetMissingRequirements {
  id: string | undefined;
  type: string | undefined;
  msa: string | undefined;
  state: string | undefined;
  region: string | undefined;
}

interface UseGetMissingRequirementsParams {
  workerId: string;
  workplace: WorkplaceDataForGetMissingRequirements;
  qualifications?: string[];
}

function getMissingRequirements(
  hcpRequirementStatus: HcpRequirementStatusApiResponse,
  workplace: WorkplaceDataForGetMissingRequirements
) {
  const pendingDocuments = new Set<HcpRequirement>();
  const missingDocuments = new Set<HcpRequirement>();

  hcpRequirementStatus.requirements.forEach((requirement) => {
    if (
      hcpRequirementStatus.completed?.includes(requirement.reqId) ||
      [...pendingDocuments].some((document) => document.reqId === requirement.reqId) ||
      [...missingDocuments].some((document) => document.reqId === requirement.reqId)
    ) {
      return;
    }

    let isDocumentRequiredforFacilityType = false;
    if (
      isDefined(workplace) &&
      isDefined(workplace.type) &&
      requirement.hcfType.includes(workplace.type)
    ) {
      isDocumentRequiredforFacilityType =
        requirement.requiredBy.includes(workplace.msa ?? "") ||
        requirement.requiredBy.includes(workplace.state ?? "") ||
        requirement.requiredBy.includes(workplace.region ?? "") ||
        requirement.level === MappingLevel.GLOBAL;
    }

    const isDocumentRequired =
      isDocumentRequiredforFacilityType || requirement.requiredBy.includes(workplace.id ?? "");

    if (isDocumentRequired) {
      if (hcpRequirementStatus.pending?.includes(requirement.reqId)) {
        pendingDocuments.add(requirement);
      } else {
        missingDocuments.add(requirement);
      }
    }
  });

  return {
    pendingDocuments,
    missingDocuments,
  };
}

export async function invalidateMissingRequirements(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [`${GET_REQUIREMENTS_STATUS_PATH}/missing`] });
}

export function useGetMissingRequirementsForWorkplaces(
  workerId: string,
  workplaces: WorkplaceDataForGetMissingRequirements[],
  qualifications?: string[],
  options: UseQueryOptions<HcpRequirementStatusResponse> = {}
): UseQueryResult<HcpRequirementStatusResponse> & UseGetMissingRequirementsByWorkplaces {
  const workplaceIds = workplaces.map((w) => w.id).filter((id): id is string => id !== undefined);
  const response = useQuery({
    queryKey: [`${GET_REQUIREMENTS_STATUS_PATH}/missing`, workerId, qualifications, workplaceIds],
    queryFn: deprecatedGetRequirementStatusDoNotUseGraphQlCH23034(
      workerId,
      workplaceIds.length > 0 ? workplaceIds : undefined,
      qualifications
    ),
    meta: { logErrorMessage: APP_V2_APP_EVENTS.GET_REQUIREMENT_STATUS_FAILURE },
    refetchInterval: minutesToMilliseconds(5),
    refetchIntervalInBackground: true,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always",
    ...options,
  });

  const hcpRequirementStatus =
    response.data?.data.hcpRequirementStatus ?? defaultHcpRequirementStatusApiResponse;

  return {
    ...response,
    results: new Map(
      workplaces.map((workplace) => {
        const id = workplace.id ?? "";
        return [id, getMissingRequirements(hcpRequirementStatus, workplace)];
      })
    ),
  };
}

export function useGetMissingRequirements(
  params: UseGetMissingRequirementsParams,
  options: UseQueryOptions<HcpRequirementStatusResponse> = {}
): UseQueryResult<HcpRequirementStatusResponse> & UseGetMissingRequirements {
  const { results, ...response } = useGetMissingRequirementsForWorkplaces(
    params.workerId,
    [params.workplace],
    params.qualifications,
    options
  );

  return {
    ...response,
    ...(results.get(params.workplace.id ?? "") ?? {
      pendingDocuments: new Set(),
      missingDocuments: new Set(),
    }),
  };
}
